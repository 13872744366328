import React from 'react'
import styled from 'styled-components'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { useTranslation } from 'react-i18next'
import Logo from '../../assets/images/solution/logo.svg'
import { Max768Wrapper, Min768Wrapper } from '../common/ResponsiveWrapper'

function SolutionSecondSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			SolutionImage1: file(
				relativePath: { eq: "images/solution/SolutionImage1.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Background>
			<FullWidth>
				<HeadingContainer>
					{/* <Logo /> */}
					<TitleContainer>
						<Min768Wrapper>
								<TitleBlack><b>{t('SolutionSecondSection.titleBlack.em')}</b>{t('SolutionSecondSection.titleBlack.text')}</TitleBlack>
						</Min768Wrapper>
						<Max768Wrapper>
								<TitleBlack><b>{t('SolutionSecondSection.titleBlackMax768.em')}</b>{t('SolutionSecondSection.titleBlackMax768.text')}</TitleBlack>
						</Max768Wrapper>
					</TitleContainer>
				</HeadingContainer>
				<ImageContainer>
					<ImageWrapper>
						<Img style={{minHeight: 600}} fluid={ data.SolutionImage1.childImageSharp.fluid }  objectFit="cover" objectPosition="50% 50%"/>
					</ImageWrapper>
				</ImageContainer>
				<DescriptrionContainer>
					<DescriptionBlack>
						{t('SolutionSecondSection.description.t1')}
						<b>{t('SolutionSecondSection.description.b1')}</b>
						{t('SolutionSecondSection.description.t2')}
						<b>{t('SolutionSecondSection.description.b2')}</b>
						{t('SolutionSecondSection.description.t3')}
						<b>{t('SolutionSecondSection.description.b3')}</b>
						{t('SolutionSecondSection.description.t4')}
					</DescriptionBlack>
				</DescriptrionContainer>
			</FullWidth>
		</Background>
	)
}

const Background = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	width: 100vw;
`

const FullWidth = styled.div`
	padding-top: 300px;
	margin: 0 auto;

	@media (min-width: 1440px) {
		width: 1008px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	
	@media (min-width: 768px)  {
		gap: 48px;
	}

	@media (max-width: 767px)  {
		gap: 20px;
		svg {
			width: 300px;
		}
	}
`

const TitleContainer = styled.div`
	white-space: pre-line;
`

const TitleBlack = styled.span`
	color: black;
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 72px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 32px;
		line-height: 44px; /* 122.222% */
	}

	b {
		background: linear-gradient(to right, #fbf216, #35b989);
		color: transparent;
		background-clip: text;
		-webkit-background-clip: text;
	}
`


const DescriptrionContainer = styled.div`
	@media (min-width: 1440px) {
		white-space: pre-line;
		margin-top: 240px;
		width: 820px;
		margin-bottom: 150px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		margin-top: 200px;
		margin-bottom: 100px;
	}

	@media (max-width: 768px) {
	}
`

const DescriptionBlack = styled.span`
	color: black;

	@media (min-width: 1440px) {
		font-size: 28px;
		line-height: 40px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 24px;
		line-height: 36px; /* 120% */
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
`

const ImageContainer = styled.div`
	display: flex;
	justify-content: center;

	@media (max-width: 768px) {
		width: 100vw;
		height: 720px;
	}
`

const ImageWrapper = styled.div`
	@media (min-width: 1440px) {
		padding-top: 20px;
		width: 100%;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 32px;
		width: 688px;
	}
	@media (max-width: 768px) {
		padding-top: 48px;
		position: absolute;
		left: 0px;
		width: 100.5vw; /* 화면 너비와 동일하게 설정 */
		height: 600px;

		img {
			min-height: 600px;
		}
	}
`

const OntologiaContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 0 auto;

	@media (min-width: 1440px) {
		padding-top: 41px;
		padding-right: 41px;
		width: 753px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 58px;
		width: 688px;
	}
	@media (max-width: 768px) {
		padding-top: calc(100vw + 60px);
		margin: 0 -10px;
	}
`

const OntologiaTitle = styled.div`
	font-weight: 900;

	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 80px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 60px;
		line-height: 80px;
	}
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 44px;
	}
`

const OntologiaDescription = styled.div`
	color: #a5a5a5;
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 25px;
		line-height: 50px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 25px;
		line-height: 50px;
	}
	@media (max-width: 768px) {
		font-size: 16px;
		line-height: 24px;
	}
`


export default SolutionSecondSection
